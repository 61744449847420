<template>
<header class="header_generique clearfix">
  <b-navbar toggleable="xl" type="dark" variant="">
    <b-navbar-brand :to="{name: 'home'}">
      <div class="contain-logo">
        <img src="/static/img/logo_solverasch_com.png" alt="solverasch.com"/>
      </div>
      <span v-if="'test version' === 1" style="color:#ffa500;"><i class="fa fa-wrench"></i> VERSION TEST</span>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <!-- Collect the nav links, forms, and other content for toggling -->
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto nav">
        <b-nav-item-dropdown no-caret>
          <template v-slot:button-content>
            <img :src="'/static/img/'+$language.current+'.png'"/>
          </template>
            <b-dropdown-item v-for="(language, key) in $language.available" href="#" class="locale_link" @click="setLanguage(key)" :key="key"><img :src="'/static/img/'+key+'.png'"/> {{ language }}</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right v-if="user_is_staff" text="Admin" id="li_admin">
          <b-dropdown-item href="/admin" title="Accéder à l'admin">Accéder à l'admin</b-dropdown-item>
          <b-dropdown-item :to="{name: 'dashboard'}">Tableau de bord erasch</b-dropdown-item>
          <b-dropdown-item href="#" v-b-modal.LoginAsModal>Connexion en tant que</b-dropdown-item>
          <b-dropdown-item @click="refreshArticlesSilog()">Rechargement forcé de Silog</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :to="{name: 'book'}" id="li_book" v-b-tooltip.hover.bottom="bookTooltipTitle">
          <b-badge variant="primary">New</b-badge>&nbsp;
          <span class="txt_icon"><translate>Découverte</translate></span>
          <!-- <i class="fas fa-book-reader fa-lg"></i> -->
        </b-nav-item>
        <template v-if="loggedIn">
        <b-nav-item :to="{name: 'new_need'}" id="li_new_need">
          <span class="txt_icon"><translate>Nouveau raccord</translate></span>
          <i class="fas fa-dice-d6 fa-lg"></i>
        </b-nav-item>
        <b-nav-item :to="{name: 'need_list'}" id="li_needs" key="li_needs">
          <span class="txt_icon" v-translate>Mes demandes</span>
          <i class="fa fa-list-alt fa-lg"></i>
        </b-nav-item>
        <b-nav-item-dropdown no-caret right id="user_account_actions">
          <template slot="button-content">
            <span class="txt_icon">{{ user.username }}</span>
            <i class="fa fa-user fa-lg"></i>
          </template>
            <b-dropdown-item href="/accounts/logout"><translate>Déconnexion</translate></b-dropdown-item>
            <b-dropdown-item href="/accounts/password_change"><translate>Changer mon mot de passe</translate></b-dropdown-item>
        </b-nav-item-dropdown>
        </template>
        <template v-else>
        <b-nav-item href="/accounts/login" id="li_login">
          <span class="txt_icon"><translate>Connexion</translate></span>
          <i class="fa fa-sign-in-alt"></i>
        </b-nav-item>
        <b-nav-item href="/accounts/register">
          <span class="txt_icon"><translate>Créez votre profil</translate></span>
          <i class="fa fa-user"></i>
        </b-nav-item>
        </template>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  <b-modal id="LoginAsModal" ok-only @ok="loginAs" ok-title="Connexion" title="Se connecter en tant que">
    <form class="form-control border-0" action="/fr/accounts/login_as/" ref="login_as_form" @submit.prevent="loginAs">
      <input type="text" placeholder="Identifiant" class="form-control" name="username" id="id_username">
      <input type="hidden" name="password" id="id_password" value="NC">
      <input type="hidden" name="next" value="" />
    </form>
  </b-modal>
</header>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import api from '@/services/api'
import { displayInfo } from '@/components/need_form/utils' 
import { displayWarning } from '@/components/need_form/utils' 
export default {
  data () {
    return {}
  },
  mounted () {
    ['fr', 'en', 'de'].forEach((code)=> {
      if (document.URL.includes(`/${code}/`)) {
        this.setLanguage(code)
        this.$router.push({ name: 'home' })
      }
    })
  },
  methods: {
    ...mapMutations([
      'setUser'
    ]),
    setLanguage: function (code) {
      let formData = new FormData()
      formData.append('language', code)
      api.post('i18n/setlang/', formData).then(
        /* eslint-disable no-unused-vars */
        (response) => {
          this.$cookies.set('django_language', code)
          this.$language.current = code
        }
      )
    },
    loginAs () {
      var form_ref = this.$refs.login_as_form
      let formData = new FormData(form_ref)
      api.post(
        form_ref.getAttribute('action'),
        formData
      ).then((response) => {
        this.setUser(response.data)
      }).catch(error => {
        alert('Oups ! Une erreur est survenue')
      })
    },
    refreshArticlesSilog () {
      api.get('api/refreshsilog/').then((response)=> {
        const message = [`${response.data.options.nb_missing} `+ this.$gettext(`Article(s) sont manquants dans Silog`),
        `${response.data.options.nb_created} `+ this.$gettext(`Article(s) ont été créés`),
        `${response.data.options.nb_updated} `+ this.$gettext(`Article(s) ont été modifiés`),
        this.$gettext(`Succès de la mise à jour Silog.`)]
        if (response.data.success) {
          message.forEach(line=> {
            displayInfo(line)
          })
        } else {
          displayWarning(this.$gettext(`Echec de la mise à jour`))
        }
      })
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    ...mapGetters([
      'user_is_staff',
      'loggedIn'
    ]),
    bookTooltipTitle: function () {
      return this.$gettext('Découvrez les possibilités du configurateur avec des exemples')
    }
  }
}
</script>