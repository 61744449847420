import Vue from 'vue'
import api from '@/services/api'
export default {
  namespaced: true,
  state: {
    stdrh_states: {},
    need_states: {},
    displayed_mp_url: ''
  },
  actions: {
    loadNeed: function ({ dispatch }, { id }) {
      api.get('/api/json/need/' + id).then(
        function (resp) {
          dispatch('storeNeed', resp.data)
        })
    },
    loadNeedMessages: function ({ commit }, { id }) {
      api.get('/api/json/need/messages/' + id).then(
        function (resp) {
          commit('updateNeedMessages', {need_id: id, messages: resp.data})
        })
    },
    storeNeed: function ({ commit }, { need_data, stdrhs }) {
      commit('addNeed', need_data)
      stdrhs.forEach(function(stdrh_data) {
        commit('AddStdRH', stdrh_data)
      })
    },
    addManualConfig: function ({ commit, state }, { need_id, stdrh_data }) {
      commit('AddStdRH', stdrh_data)
      if (!state.need_states[need_id].stdrh_ids.length) {
        state.need_states[need_id].proposed_config_id = stdrh_data.id
      }
      state.need_states[need_id].stdrh_ids.push(stdrh_data.id)
    }
  },
  mutations: {
    setKeyVal: function(state, data) {
      state.stdrh_states[data.id][data.key] = data.value
    },
    reloadDisplayUrls: function(state, data) {
      // Add random nb in query string on img url to force browser to reload
      state.stdrh_states[data.id].mp_random_query_string = Math.floor(Math.random() * 1000) + 1
    },
    addNeed: function (state, need_data) {
      Vue.set(state.need_states, need_data.id, need_data)
    },
    updateNeedMessages: function (state, data) {
      Vue.set(state.need_states[data.need_id], 'messages', data.messages)
    },
    AddStdRH: function (state, data) {
      data.mp_random_query_string = 0
      data.pending_step = false
      data.pending_client_step = false
      data.pending_mp = false
      data.step_error = ""
      data.need_3d_files_regen = false
      Vue.set(state.stdrh_states, data.id, data)
    },
    setProposedConfig: function (state, data) {
      Vue.set(state.need_states[data.need_id], 'proposed_config_id', data.stdrh_id)
    },
    delStdrh: function (state, data) {
      var need_data = state.need_states[data.need_id]
      var stdrh_ids = need_data.stdrh_ids
      // Delete stdrh id from need stdrh id list
      var index_to_delete = stdrh_ids.indexOf(data.stdrh_id)
      stdrh_ids.splice(index_to_delete, 1)
      // Change proposed config id if stdrh was proposed config
      if (need_data.proposed_config_id === data.stdrh_id) {
        need_data.proposed_config_id = (stdrh_ids.length ? stdrh_ids[0] : null)
      }
    }
    // toggleShowAll: function(state, data){
    //   var need_state = state.need_states[data.id]
    //   Vue.set(need_state, 'show_all_configs', !need_state.show_all_configs)
    // }
  },
  getters: {
    get_key: function(state){
      return function(id, key) {
        return state.stdrh_states[id][key]
      }
    },
    mp_url: function(state){
      return function(id) {
        return state.stdrh_states[id].mp_url + "?" + state.stdrh_states[id].mp_random_query_string
      }
    },
    x3d_url: function(state){
      return function(id) {
        return state.stdrh_states[id].x3d_url + "?" + state.stdrh_states[id].mp_random_query_string
      }
    },
    nb_stdrh: function(state){
      return Object.keys(state.stdrh_states).length
    },
    get_need: function (state) {
      return function (id) {
        return state.need_states[id]
      }
    },
    get_stdrh: function (state) {
      return function (id) {
        return state.stdrh_states[id]
      }
    }
  }
}