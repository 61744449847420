import Vue from 'vue'
import api from '@/services/api'
import {number_of_dims, number_of_faces} from '@/components/need_form/utils'
var _ = require('lodash')
import FormData from 'form-data'

export default {
  namespaced: true,
  state: {
    stage_nb: 1,
    interface_search: false,
    interface_focus: null,
    interface_choices: {},
    restrictions: {},
    check_valves: {},
    interfaces_data: {},
    grouped_interfaces_data: [],
    grouped_long_body_interfaces_data: [],
    groups_data: {},
    sealing_map: {},
    pending_get_itf_datas: false,
    MAX_BRAZINGS: 2,
    MAIN_LOC: "iym1",
    locked_need: false,
    display_filters: true,
    search_phrase: "",
    filters: {
      norm: "",
      i_type: "",
      gender: ""
    },
    itf_data_updates: 0 // Exists only for need list watch and update search phrases
  },
  actions: {
    getItfDatas: function(context) {
      context.commit('gettingItfData', true)
      api.get(context.rootState.api_urls.json_interfaces_data)
      .then(function(response) {
        context.commit('setInterfacesData', response.data)
        context.commit('gettingItfData', false)
        context.commit('setItfSearchPhrases')
      })
      .catch(function(e) { console.log(e) })
    }
  },
  mutations: {
    lockForm: function(state) {
      state.locked_need = true
    },
    unlockForm: function(state) {
      state.locked_need = false
    },
    focusOn: function(state, location){
      state.interface_focus = location;
      if (!state.interface_choices[location]){
        state.interface_search = true;
      }
    },
    unFocus: function(state){
      state.interface_focus = null;
    },
    choose: function(state, itf_id){
      state.interface_search = false;
      if (state.interface_focus){
        Vue.set(state.interface_choices, state.interface_focus, itf_id);
        if (!state.restrictions[state.interface_focus]){
          Vue.set(state.restrictions, state.interface_focus, "");
        }
      }
    },
    setRestriction: function(state, data){
      Vue.set(state.restrictions, data.location, data.value);
    },
    setCheckValve: function(state, data){
      Vue.set(state.check_valves, data.location, {
        way: data.way_val,
        pressure: data.pressure_val
      });
    },
    searchInterface: function(state){
      state.interface_search = true;
    },
    outInterfaceSearch: function(state){
      state.interface_search = false;
    },
    cancelChoice: function(state, location){
      Vue.delete(state.interface_choices, location);
      Vue.delete(state.restrictions, location);
      Vue.delete(state.check_valves, location);
    },
    setStageNumber: function(state, n){
      state.stage_nb = n;
    },
    setInterfacesData: function(state, data){
      state.interfaces_data = data.interfaces_data;
      state.grouped_interfaces_data = data.grouped_interfaces_data;
      state.grouped_long_body_interfaces_data = data.grouped_long_body_interfaces_data;
      state.groups_data = data.itf_groups_data;
      state.sealing_map = data.sealing_map;
    },
    setItfSearchPhrases: function (state) {
      Object.values(state.interfaces_data).forEach (itf => {
        var g = state.groups_data[itf.group_id]
        var groups = [g]
        while (g.parent_id) {
          var parent = state.groups_data[g.parent_id]
          groups.push(parent)
          g = parent
        }
        groups = groups.reverse().slice(0, 2) // Do not keep sealing group (3rd)
        itf.search_phrase = groups.map(g => g.title).join(' ')+ ' ' + itf.caliber_display
      })
      state.itf_data_updates += 1
    },
    gettingItfData: function(state, data){
      state.pending_get_itf_datas = data;
    },
    initNeedFormValues: function(state){
      state.interface_choices = state.init_interface_choices;
      state.restrictions = state.init_restrictions;
      state.check_valves = state.init_check_valves;
      state.stage_nb = state.init_stage_nb;
    },
    initNeedEdit: function (state, data) {
      state.interface_choices = data.init_interface_choices
      state.restrictions = data.init_restrictions
      state.check_valves = data.init_check_valves
      state.stage_nb = data.init_stage_nb
    },
    resetNeedForm: function (state) {
      state.interface_choices = {}
      state.restrictions = {}
      state.check_valves = {}
      state.stage_nb = 1
    }
  },
  getters: {
    isFieldDisabled: function(state, getters){
      return function(location){
        if (state.locked_need) { // locked need (ConfigBook), unfilled locations are disabled
          return !state.interface_choices[location]
        }
        if (location == state.MAIN_LOC || state.stage_nb == 1) {
          // body front face or 1-stage need : no disabled field
          return false;
        } else if (!getters.isMainInterfaceSelected){
          // body front face not selected, all other fields are disabled
          return true;
        } else {
          var locs = getters.filledAdapterLocations();
          locs.push(location); // too many brazings with new location ?
          return getters.nbBrazingsWithLocations(locs) > state.MAX_BRAZINGS;
        }
      };
    },
    restrictionAllowed: function(state, getters){
      return function(location) {
        return (!(
          (location == state.MAIN_LOC && state.stage_nb > 1) ||
          _.get(getters.interfaceChoice(location), 'i_type') === "PP"));
        };
    },
    cvAllowed: function(state, getters){
      return function(location) {
        return (!(
          (location == state.MAIN_LOC && state.stage_nb > 1) ||
          !_.get(getters.interfaceChoice(location), 'check_valve_ok')));
        };
    },
    interfaceChoice: function(state){
      return function(location) {
        var itf_id = state.interface_choices[location];
        if (!itf_id) { return; }
        return state.interfaces_data[itf_id];
      };
    },
    isMainInterfaceSelected: function(state) {
      return (state.MAIN_LOC in state.interface_choices);
    },
    filledAdapterLocations: function(state) {
      return function(){
        var locs = Object.keys(state.interface_choices);
        var idx = locs.indexOf(state.MAIN_LOC);
        if (idx > -1) { locs.splice(locs.indexOf(state.MAIN_LOC), 1); }
        return locs;
      }
    },
    nbBrazings: function(state, getters){
      return function() {
        var adapters_locations = getters.filledAdapterLocations();
        return getters.nbBrazingsWithLocations(adapters_locations);
      };
    },
    nbBrazingsWithLocations: function(state, getters){
      return function(adapters_locations) {
        if (getters.isMainInterfaceSelected && _.get(getters.interfaceChoice(state.MAIN_LOC), 'CA') <= 19){
          return number_of_dims(adapters_locations);
        } else {
          return number_of_faces(adapters_locations);
        }
      };
    },
    getFocusedInterdaceChoice: function(state, getters){
      if (state.interface_focus){
        return getters.interfaceChoice(state.interface_focus)
      }
    },
    interfacesFormData: function (state) {
      var formData = new FormData()
      _.forOwn(state.interface_choices, function(value, loc) {
        if (value) {
          formData.append('interface_' + loc, value)
        }
      })
      _.forOwn(state.restrictions, function(value, loc) {
        if (value) {
          formData.append('restriction_' + loc, value)
        }
      })
      _.forOwn(state.check_valves, function(value, loc) {
        if (value) {
          if (value.way) { formData.append('cv_way_' + loc, value.way) }
          if (value.pressure) { formData.append('cv_pressure_' + loc, value.pressure) }
        }
      })
      return formData
    }
  }
}
