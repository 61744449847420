import api from '@/services/api'
var _ = require('lodash')
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    need_list: [],
    loading_needs: false,
    needs_loaded: false,
    filter: '',
    url_needs: '/api/json/need_list'
  },
  actions: {
    updateData: function ({ dispatch, state }, last_id=null) {
      if (state.needs_loaded || last_id) {
        dispatch('loadAllNeeds', last_id)
      }
    },
    loadData: function ({ dispatch }) {
      dispatch('loadLastNeeds').then(() => {
        dispatch('loadAllNeeds')
      })
    },
    loadLastNeeds: function ({ state }) {
      state.loading_needs = true
      // console.log('loadLastNeeds', rootState.api_urls.need_list)
      return api.get(state.url_needs)
      .then(function(response) {
        state.loading_needs = false
        state.need_list = response.data.data
      })
      .catch(function(e) { console.log(e) })
    },
    loadAllNeeds: function ({ state }, last_id=null) {
      let keyword = last_id ? `?last_id=${last_id}` : '?all=1'
      return api.get(state.url_needs + keyword)
      .then(function(response) {
        let new_data = response.data
        if (new_data.msg != 'no new need') {
          state.need_list = new_data.data
          state.needs_loaded = true
        }
      })
      .catch(function(e) { console.log(e) })
    }
  },
  mutations: {
    removeNeed: function(state, data) {
      var need_idx = _.findIndex(state.need_list, {id: data.id})
      if (need_idx > -1) {
        Vue.delete(state.need_list, need_idx)
      }
      // _.remove(state.need_list, {id: data.id})
    },
    updateNeedStatus: function(state, data) {
      var need_idx = _.findIndex(state.need_list, {id: data.id})
      Vue.set(state.need_list[need_idx], 'status', data.status)
    }
  },
  getters: {}
}