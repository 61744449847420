import Vue from 'vue'
import Router from 'vue-router'
// import NeedForm from '@/views/NeedForm.vue'
// import NeedList from '@/views/NeedList.vue'
// import NeedDetail from '@/views/NeedDetail.vue'
import NProgress from 'nprogress'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
    },
    {
      path: '/new_need',
      name: 'new_need',
      // component: NeedForm
      component: () => import(/* webpackChunkName: "form" */ '@/views/NeedForm.vue'),
      meta: { login_required: true }
    },
    {
      path: '/needs',
      name: 'need_list',
      // component: NeedList
      component: () => import(/* webpackChunkName: "list" */ '@/views/NeedList.vue'),
      meta: { login_required: true }
    },
    {
      path: '/need/:id',
      name: 'need_detail',
      // component: NeedDetail
      component: () => import(/* webpackChunkName: "detail" */ '@/views/NeedDetail.vue'),
      meta: { login_required: true }
    },
    {
      path: '/need/edit/:id',
      name: 'need_edit',
      // component: NeedForm
      component: () => import(/* webpackChunkName: "form" */ '@/views/NeedForm.vue'),
      meta: { login_required: true }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "form" */ '@/views/Dashboard.vue'),
      meta: { login_required: true }
    },

    {
      path: '/book',
      name: 'book',
      // component: NeedList
      component: () => import(/* webpackChunkName: "list" */ '@/views/ConfigBook.vue'),
      meta: { login_required: false }
    },
  ]
})

/* eslint-disable no-unused-vars */
router.beforeResolve(async (to, from, next) => {
  if (to.meta.login_required) {
    var user = await store.state.user
    if (!user.logged_in) {
      window.location.href = `/accounts/login?next=${to.fullPath}`
      return
    }
  }
  if (to.name) {
    // If this isn't an initial page load.
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})


/* eslint-disable no-unused-vars */
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router