import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import GetTextPlugin from 'vue-gettext'
import translations from '../locale/translations.json'
import VueCookies from 'vue-cookies'
import BootstrapVue from 'bootstrap-vue'
import 'nprogress/nprogress.css'
import 'core-js' // polyfills for IE

import * as Sentry from '@sentry/vue'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler, LineController, ScatterController, TimeScale, TimeSeriesScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler, LineController, ScatterController, TimeScale, TimeSeriesScale)


// Sentry for Vue
const environment = process.env.NODE_ENV

if (environment === 'production') {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn: 'https://0449886157334049b29278856f680552@o701742.ingest.sentry.io/4505290385326080',
  })
}

Vue.use(BootstrapVue)

Vue.use(GetTextPlugin, {
  translations: translations,
  defaultLanguage: 'fr',
  availableLanguages: {
    fr: 'Français',
    en: 'English',
    de: 'Deutsch'
  },
  silent: true
})

Vue.config.productionTip = false

Vue.use(VueCookies)

Vue.directive('focus', {
  // définition de la directive
  inserted: function (el) {
    el.focus()
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
