var _ = require('lodash')
var toastr = require('toastr')
export function sign_to_letter(sign){
  switch(sign){ case '+': return 'p'; case '-': return 'm';}
}
export function letter_to_sign(sign){
  switch(sign){ case 'p': return '+'; case 'm': return '-';}
}
export function dim_color(dim){
  switch(dim) {
    case "x":
        return "green";
    case "y":
        return "red";
    case "z":
        return "blue";
    default:
        return "";
  }
}
export function number_of_dims(locations){
  var s = new Set(_.map(locations, function(loc){ return loc[1]; }));
  return s.size;
}
export function number_of_faces(locations){
  var s = new Set(_.map(locations, function(loc){ return loc.slice(1, 3); }));
  return s.size;
}
export function displayInfo(text){
  toastr.info(text, '', {positionClass: "toast-bottom-left"});
}
export function displayWarning(text){
  toastr.warning(text, '', {positionClass: "toast-bottom-left"});
}
export function displayMessages (msg_list) {
  if (!msg_list) { return }
  msg_list.forEach(function (elt) {
    toastr[elt[0]](elt[1], '', {
      positionClass: "toast-bottom-right",
      newestOnTop: false,
      timeOut: "7000"
    })
  })
}
/* better but IE does not support normalize() method
export function stripAccents(text){
  // source https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}*/

export function stripAccents(str) {
  var accents    = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  var accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split('');
  var strLen = str.length;
  var i, x;
  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(str[i])) != -1) {
      str[i] = accentsOut[x];
    }
  }
  return str.join('');
}

export function itfInHierarchy (itf_id, group) {
  // Checks if itf is an option of this group or its subgroups
  return (group.options.indexOf(itf_id) > -1 || _.some(group.subgroups, (sg) => {
    return itfInHierarchy(itf_id, sg) }))
}